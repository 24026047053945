import request from "./request";

//新增部门管理
export function deptAdd(data) {
    return request({
        url: '/project/dept',
        method: 'post',
        data
    })
}

//修改部门管理
export function deptPut(data) {
    return request({
        url: '/project/dept',
        method: 'put',
        data
    })
}

//获取部门管理
export function getDept(id) {
    return request({
        url: '/project/dept/' + id,
        method: 'get'
    })
}

//删除部门管理
export function deptDeltel(ids) {
    return request({
        url: '/project/dept/' + ids,
        method: 'delete'
    })
}

//导出部门管理
export function deptExport() {
    return request({
        url: '/project/dept/export',
        method: 'post'
    })
}

//导入部门管理
export function deptImportData(data) {
    return request({
        url: '/project/dept/importData',
        method: 'post',
        data
    })
}

//获取部门管理列表
export function deptList(query) {
    return request({
        url: '/project/dept/list',
        method: 'get',
        params: query
    })
}

//获取部门人员
export function getUserAndDept(deptId) {
    return request({
        url: '/project/dept/getUserAndDept/' + deptId,
        method: 'get'
    })
}

//获取全部部门人员信息
export function getAllUserAndDept() {
    return request({
        url: '/project/dept/getAllUserAndDept',
        method: 'get'
    })
}