<template>
  <div class="item">
    <ul>
      <li v-for="(l, id) of list.deptList" :key="id">
        <van-collapse v-model="activeName" accordion lazy-render @change="getDept(l.deptId)">
          <van-collapse-item :title="l.deptName" :name="id">
            <ul style="padding: 0 20px" v-if="userAndDept">
              <li>
                <index-chird :list="userAndDept" />
              </li>
            </ul>
          </van-collapse-item>
        </van-collapse>
      </li>
      <div style="display: flex; flex-wrap: wrap; margin-top: 1vh">
        <li v-for="it in list.userList" :key="it.userId" @click="choose(it)">
          <img v-if="it.avatar" :src="it.avatar" alt="" class="avatar" />
          <div v-else class="avatar" style="background:#307FFD;margin-bottom: 5px;">
            <div style="line-height:3rem;color: #fff;font-size: 0.5em;">{{ it.name }}</div>
          </div>
          <div>{{ it.name }}</div>
        </li>
      </div>
    </ul>

  </div>
</template>

<script>
import Vue from "vue";
import { getUserAndDept } from "../api/dept";
import { Collapse, CollapseItem } from "vant";

Vue.use(Collapse);
Vue.use(CollapseItem);

export default {
  name: "index-chird", // 自身组件
  props: {
    list: Object
  },
  data() {
    return {
      activeName: "1",
      content: 0,
      // count: 0,
      type: 'chooseList',
      userAndDept: {}
    };
  },
  watch: {
    list(newData) {
      this.list = newData;
    },
  },

  mounted() {
    this.type = window.localStorage.getItem('type')
    // this.$bus.$on("chooseIndex", (data) => {
    //   console.log("A组件，收到了数据:", data);
    //   this.count = data;
    // });
  },
  methods: {
    choose(it) {
      let chooseList = window.localStorage.getItem(this.type || '[]');
      if (chooseList) {
        let A = JSON.parse(chooseList);

        let flag = false;
        A = A.filter((item) => {
          if (item.userId == it.userId) {
            flag = true;
          }
          return item.userId != it.userId;
        });
        if (!flag) {
          A.push(it);
        }
        let B = JSON.stringify(A);
        window.localStorage.setItem(this.type, B);
      } else {
        let t = [];
        t.push(it);
        let a = JSON.stringify(t);
        window.localStorage.setItem(this.type, a);
      }
      // this.count = this.count + 1;
      // this.$bus.$emit("chooseIndex", this.count);
    },
    getDept(deptId) {
      getUserAndDept(deptId).then(res => {
        this.userAndDept = res.data.data

      })
    }
  },
};
</script>

<style style="less" scoped>
/* /deep/ .van-collapse-item__content {
  padding: 0;
}

/deep/ .van-cell__title {
  text-align: left;
} */

li {
  color: #969799;
  font-size: 14px;
  line-height: 1.5;
}

.item {
  margin: 0 auto;
}

.avatar {
  width: 3rem;
  height: 3rem;
  margin: 0 10px;
}
</style>
