<template>
    <div>
        <!-- 递归组件 -->
        <Select :list="list" :type="type" style="margin-bottom:20vh" />
        <div class='isChoose'>
            <div>已选择:</div>
            <div style="flex:1;white-space: nowrap; overflow: auto;margin:0 10px;padding: 5px;">
                <van-badge v-for="item in isChoose" :key="item" style="margin-right:10px" @click="delChooseList(item)">
                    <template #content>
                        <van-icon name="cross" class="badge-icon" @click="delChooseList(item)" />
                    </template>
                    <span @click="delChooseList(item)">{{ item }} </span>
                </van-badge>

            </div>
            <van-button round block type="info" native-type="submit" size="normal" @click="submitList">确定</van-button>
        </div>
    </div>
</template>

<script>
import Vue from "vue";

import Select from "../components/select.vue";
import { getUserAndDept } from "../api/dept";
import { Icon, Badge, Button } from 'vant';
Vue.use(Icon)
Vue.use(Badge)
Vue.use(Button)
export default {
    name: "index-Select",
    components: {
        Select,
    },
    data() {
        return {
            list: {},
            isChoose: [],
            routerPath: '/',
            type: ''
        };
    },
    created() {
        this.type = this.$route.query.type
        this.getAllDept();
        let t = JSON.parse(window.localStorage.getItem(this.type) || '[]') || []
        window.localStorage.setItem('type', this.type)
        this.isChoose = t.map(it => { return it.name })
        //添加时间监听
        window.addEventListener('setItemEvent', (e) => {
            if (e.key !== 'type') {
                const newdata = JSON.parse(e.newValue)
                this.isChoose = newdata.map(item => {
                    return item.name
                })
            }

        })
    },
    methods: {
        getAllDept() {
            getUserAndDept(1).then((res) => {
                this.list = res.data.data;
            });
        },
        submitList() {
            this.routerPath = this.$route.query.routerPath
            this.$router.back();
        },
        delChooseList(item) {
            let t = JSON.parse(window.localStorage.getItem(this.type)) || []
            let list = t.filter(it => {
                return it.name !== item
            })
            window.localStorage.setItem(this.type, JSON.stringify(list))
        }
    },
};
</script>

<style lang="less" scoped>
.isChoose {
    position: fixed;
    width: 100vw;
    height: 10vh;
    line-height: 10vh;
    bottom: 0vh;
    background: #fff;
    border-top: 1px solid #969799;
    border-radius: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
    padding: 0 10px;
}

/deep/ .van-button--block {
    width: unset;
}

/deep/.van-badge--fixed {
    top: 25px;

}

// select样式
/deep/ .van-collapse-item__content {
    padding: 0;
}

/deep/ .van-cell__title {
    text-align: left;
}
</style>

